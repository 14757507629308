import React from 'react'

function Footer() {
    return (
        <div className='footer'>
            <p className='m-0 py-3 text-center text-white'>All rights reserved 2023 | Developed by  <a className='text' href="https://t.me/islomjummayev"> Jummayev islom  </a></p>
           
        </div>
    )
}

export default Footer